<template>
  <div class="all-list">
    <van-nav-bar title="专业维护" left-text="返回" fixed left-arrow @click-left="goBack">
      <template #right>
        <van-icon name="scan" size="18" @click="qrCode" />
      </template>
    </van-nav-bar>
    <van-search class="search" placeholder="请输入搜索关键词" @search="onSearch" />
    <van-tabs v-model:active="activeName" class="list-tab" scrollspy sticky  @change="handleClick">
      <van-tab name="99" title="全部">
        <template #title>全部（{{ total }}）</template>
      </van-tab>
      <van-tab name="1" title="待执行">
        <template #title>待执行（{{ todo }}）</template>
      </van-tab>
      <van-tab name="10" title="完成">
        <template #title>完成（{{ done }}）</template>
      </van-tab>
      <van-tab name="2" title="待审核人确认">
        <template #title>待审核人确认（{{ to_monitor }}）</template>
      </van-tab>
      <van-tab name="3" title="待审核人确认">
        <template #title>待审核人确认（{{ to_engineer }}）</template>
      </van-tab>
      <van-tab name="-1" :title="'超时' + '(' + timeout + ')'"></van-tab>
      <van-tab name="666" :title="'扫码' + '(' + m_total + ')'"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="isRefresh" head-height="80" distance="80"  @refresh="onRefresh">
      <van-list class="index-listPlus" v-model="loading" :finished="isFinished" :finished-text="finishedText" @load="loadMore">
        <div style="margin-top: 10px">
          <van-cell-group border>
            <van-cell
                is-link
                title="选择单个日期"
                @click="showCalendar = true"
                center
            />
          </van-cell-group>

          <van-calendar
              v-model="showCalendar"
              type="range"
              @confirm="onConfirm"
              :allow-same-day="true"
          />
        </div>
        <van-cell class="bq-list" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
          <van-swipe-cell>
            <van-row type="flex" class="tag">
              <van-col span="10">
                <div v-html="item.status_table"></div>
              </van-col>
            </van-row>
            <div>
              <van-row type="flex">
                <van-col span="18">{{ item.code }}</van-col>
              </van-row>
              <van-row type="flex">
                {{ item.type }}
              </van-row>
              <van-row type="flex">
                {{ item.admin }}
              </van-row>
              <van-row type="flex">
                <van-col style="margin-top: 6px">{{ item.workshop}}，{{ item.line}}，{{ item.station }}</van-col>
              </van-row>
            </div>
          </van-swipe-cell>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import API from '@/api/workorder/pm'
import { Toast } from "vant"
export default {
  name: "projectPage",
  inject: ['goUrl', 'reload'],

  data() {
    return {
      activeName: '99',
      list: [],
      value:'',
      loading: false,
      isFinished: false,
      isRefresh: false,
      finishedText:'加载完成',
      isLoading: false,
      finnshFeiShu: false,
      total: 0,
      todo: 0,
      done: 0,
      timeout: 0,
      m_total: 0,
      to_monitor: 0,
      to_engineer: 0,
      timeout_todo: 0,
      timeout_done: 0,
      timeout_to_monitor: 0,
      timeout_to_engineer: 0,
      countAlready: false,
      showCalendar: false,
      from: '',
      query: {
        status: null,
        type: 2,
        page: 0,
        limit: 10
      }
    }
  },
  created() {
    this.getConfig()
    const complete = this.$route.query.complete
    if(complete) {
      this.index()
    }
  },
  methods: {
    index() {
      console.log('加载了',this.query)
      this.isLoading = true
      if (this.isRefresh) {
        this.list = []
        this.query.page = 0
        this.isRefresh = false
        this.isFinished = false
      }
      this.toTop()
      API.getList(this.query).then(res => {
        this.list.push(...res.data.list.data)
        if (this.list.length >= res.data.total) {
          this.isFinished = true
        }
        if(!this.countAlready){
          this.total = res.data.total
          this.todo = res.data.todo
          this.done = res.data.done
          this.timeout = res.data.timeout
          this.to_monitor = res.data.to_monitor
          this.to_engineer = res.data.to_engineer
          this.timeout_todo =res.data.timeout_todo
          this.timeout_done =res.data.timeout_done
          this.timeout_to_monitor = res.data.timeout_to_monitor
          this.timeout_to_engineer = res.data.timeout_to_engineer
          this.countAlready = true
        }
      }).finally(() => {
        this.loading = false
        this.isLoading = false
      })
    },
    loadMore() {
      if(!this.isLoading){
        this.query.page += 1
        this.index()
      }
    },
    handleClick(name) {
      console.log(name)
      if(name === '99'){
        this.query.status = null
        this.list = []
        this.isFinished = false
        this.query.page = 1
        this.index()
      } else if (name === '666'){
        this.query.status = null
        this.list = []
      } else {
        this.query.status = parseInt(name)
        this.list = []
        this.isFinished = false
        this.query.page = 1
        this.index()
      }
    },
    goBack() {
      this.goUrl('/index')
    },
    onSearch() {
      this.query.page = 0
      this.list = []
      this.index()
    },
    goDetail(id){
      const url = `/workorder/pm3/edit/${id}?from=${this.from}`
      this.goUrl(url)
    },
    onRefresh() {
      if (this.activeName === '666'){
        if (this.isRefresh) {
          this.isRefresh = false
          this.isFinished = true
        }
      } else {
        this.index()
      }
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    onConfirm(date) {
      this.show = false;
      this.text = `选择了 ${date.length} 个日期`;
    },
    getConfig() {
      console.log('开始执行config')
      const _this = this
      API.qrCodeTicket({ url: 'https://mjianghuai.hx-im.com.cn/workorder/pm3' }).then(res => {
        console.log('res', res)
        window.h5sdk.error((err) => {
          console.log('h5sdk有问题了！！！')
          throw ("h5sdk error:", JSON.stringify(err), 6666);
        })
        window.h5sdk.config({
          appId: res.data.appid,
          timestamp: res.data.timestamp,
          nonceStr: res.data.noncestr,
          signature: res.data.signature,
          jsApiList: [],
          //鉴权成功回调
          onSuccess: (res) => {
            _this.finnshFeiShu = true
            console.log('鉴权成功了！！')
            console.log(`config success: ${JSON.stringify(res)}`)

          },
          //鉴权失败回调
          onFail: (err) => {
            console.log('鉴权失败了！！！')
            throw `config failed: ${JSON.stringify(err)}`
          },
        });
      })

    },
    qrCode() {
      console.log('点击扫码按钮了')
      const _this = this
      window.h5sdk.ready(()=> {
        console.log('不知道鉴权失败这里是否执行！！！')
        tt.scanCode({
          scanType: [
            "qrCode",
          ],
          barCodeInput: true,
          success(res) {
            console.log(JSON.stringify(res))
            const str = res.result
            const eq = str.split('|')
            if(eq[0] !== 'e') {
              Toast('这不是设备')
            } else {
              localStorage.clear()
              _this.activeName = '666'
              _this.from = 'qrcode'
              _this.isLoading = true
              if (_this.isRefresh) {
                _this.list = []
                _this.query.page = 0
                _this.isRefresh = false
                _this.isFinished = false
              }
              API.getList({ equipment_code: eq[1], type: 2 }).then(res => {
                _this.list = []
                _this.m_total = res.data.total
                _this.list.push(...res.data.list.data)
                if (_this.list.length >= res.data.total) {
                  _this.isFinished = true
                }
                _this.query.page = 1
                const needArr = []
                _this.list.forEach(item => {
                  needArr.push(item.id)
                })
                if(_this.list.length === 1) {
                  const id = _this.list[0].id
                  const url = `/workorder/pm3/edit/${id}?from=qrcode`
                  _this.goUrl(url)
                }
              }).finally(() => {
                _this.loading = false
                _this.isLoading = false
              })
            }
          },
          fail(res) {
            console.log('扫码动作失败了')
            console.log(`scanCode fail: ${JSON.stringify(res)}`)
          }
        });
      })
    },
  },
}
</script>

<style scoped>
.top-item{
  display: flex;
  justify-content: space-around;
}
</style>
