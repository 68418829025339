import request from '@/utils/request'

export default {
  getList(params) {
    return request({
      url: '/v1/workorder',
      method: 'get',
      params
    })
  },
  getOne(id) {
    return request({
      url: `/v1/workorder/${id}`,
      method: 'get'
    })
  },
  update(data) {
    return request({
      url: '/v1/workorder',
      method: 'put',
      data
    })
  },
  create(data) {
    return request({
      url: '/v1/workorder',
      method: 'post',
      data
    })
  },
  delete(data) {
    return request({
      url: `/v1/workorder`,
      method: 'get',
      data
    })
  },
  appendParts(data) {
    return request({
      url: '/v1/workorderParts',
      method: 'post',
      data
    })
  },
  qrCodeTime(data) {
    return request({
      url: '/v1/workorderQrcodeTime',
      method: 'post',
      data
    })
},
  monitorReview(data) {
    return request({
      url: '/v1/workorderMonitor',
      method: 'post',
      data
    })
  },
  engineerReview(data) {
    return request({
      url: '/v1/workorderEngineer',
      method: 'post',
      data
    })
  },
  removeParts(params) {
    return request({
      url: `/v1/workorderParts`,
      method: 'delete',
      params
    })
  },
  memberRemove(data) {
    return request({
      url: `/v1/workordeAdmin`,
      method: 'delete',
      data
    })
  },
  editParts(data) {
    return request({
      url: '/v1/workorderParts',
      method: 'put',
      data
    })
  },
  appendTask(data) {
    return request({
      url: '/v1/pmsAppendTask',
      method: 'post',
      data
    })
  },
  appendTime(data) {
    return request({
      url: '/v1/workorderTime',
      method: 'post',
      data
    })
  },
  memberAppend(data) {
    return request({
      url: '/v1/planAdmin',
      method: 'post',
      data
    })
  },
  removeTime(params) {
    return request({
      url: `/v1/workorderTime`,
      method: 'delete',
      params
    })
  },
  requestParts(data) {
    return request({
      url: `/v1/requestParts`,
      method: 'post',
      data
    })
  },
  excel(data) {
    return request({
      url: '/v1/holidayImport',
      method: 'post',
      data
    })
  },
  submit(data) {
    return request({
      url: '/v1/workorderSubmit',
      method: 'post',
      data
    })
  },
  verify(data) {
    return request({
      url: '/v1/workorderVerify',
      method: 'post',
      data
    })
  },
  qrCodeTicket(params) {
    return request({
      url: '/v1/getFeishuSignature',
      method: 'get',
      params
    })
  },
  photoUpload(data) {
    return request({
      url: '/v1/workorderImage',
      method: 'post',
      data
    })
  },
}
